import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { CaseStudiesNavigation } from './CaseStudiesNavigation'

import { getUrlVars } from '../../utils/helpers'
import { navigate } from 'gatsby'
import { CaseStudiesList } from './CaseStudiesList'
import { CaseStudiesPagination } from './CaseStudiesPagination'
import { Hero, Cta } from '../../acf'

const ThisCaseStudiesFilter = props => {
  const { data } = props
  const { caseStudies, location, sector, site } = data
  const pageAttr = getUrlVars()
  const perPage = site.siteMetadata.paginate
  const [pageContext, setPageContext] = useState({ paged: 1, numberOfPages: 1 })
  const [range, setRange] = useState(null);
  const [filteredCaseStudies, setFilteredCaseStudies] = useState(null);

  const scrollToFilter = () => {
    setTimeout(() => {
      window.scrollTo(0, 700)
    }, 0)
  }

  useEffect(() => {
    pageAttr.length >= 0 ? scrollToFilter() : navigate(`/case-studies/`) // Exit to main case studies page if no filters


    //Filter post list

    var filterCaseStudies = caseStudies && caseStudies.edges

    if (pageAttr.location) {
      var attId =
        location &&
        location.edges.find(
          e => e.node.slug === pageAttr['location']
        )
      if (attId && attId.node.wordpress_id) {
        filterCaseStudies = filterCaseStudies.filter(i =>
          i.node['location'].find(c => c === parseInt(attId.node.wordpress_id))
        )
      }
    }

    if (pageAttr.value) {
      var attId =
        sector &&
        sector.edges.find(e => e.node.slug === pageAttr['value'])
      if (attId && attId.node.wordpress_id) {
        filterCaseStudies = filterCaseStudies.filter(i =>
          i.node['sector'].find(c => c === parseInt(attId.node.wordpress_id))
        )
      }
    } else {
      if (pageAttr.sector) {
        var attId =
          sector &&
          sector.edges.find(
            e => e.node.slug === pageAttr['sector']
          )
        if (attId && attId.node.wordpress_id) {
          filterCaseStudies = filterCaseStudies.filter(i =>
            i.node['sector'].find(c => c === parseInt(attId.node.wordpress_id))
          )
        }
      }
    }

    setFilteredCaseStudies(filterCaseStudies)

    // Paginate post list

    const paged = pageAttr.page ? parseInt(pageAttr.page) : 1
    const itemCount = filterCaseStudies ? filterCaseStudies.length : 0

    const numberOfPages = Math.ceil(itemCount / perPage)
    // const nextPage = paged ? paged + 1 : 2
    // const prevPage = paged ? paged - 1 : null
    const range = paged ? perPage * paged : perPage
    const pageContext = {
      paged: paged,
      //previousPagePath: prevPage,
      //nextPagePath: nextPage <= numberOfPages ? nextPage : null,
      numberOfPages: numberOfPages,
    }
    setPageContext(pageContext)
    setRange(range)
  }, []);


  const { page } = data
  const { acf } = page
  const {
    subheading,
    heading,
    blurb,
    backgroundImage,
  } = acf.layout[0]

  if (!filteredCaseStudies) {
    return <div className="loading" />
  }

  if (filteredCaseStudies) {
    return (
      <div>
        <Hero
          subheading={subheading}
          heading={heading}
          blurb={blurb}
          backgroundImage={backgroundImage}
        />
        <section className="CaseStudiesFilter">
          <div className="wrapper">
            <CaseStudiesNavigation
              location={location}
              sector={sector}
              caseStudies={filteredCaseStudies}
              allCaseStudies={caseStudies}
            />
            <CaseStudiesList
              caseStudies={filteredCaseStudies}
              range={range}
              perPage={perPage}
              location={location}
              sector={sector}
            //data={this.state}
            />
            <CaseStudiesPagination
              pageContext={pageContext}
              pathPrefix={`/case-studies/filter/`}
            />
          </div>
        </section>
      </div>
    )
  }
}

export const CaseStudiesFilter = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          page: wordpressPage(slug: { eq: "case-studies" }) {
            yoast {
              metadesc
              title
            }
            pageTitle: title
            acf {
              layout: layout_page {
                __typename
                ... on WordPressAcf_Hero {
                  id
                  subheading
                  heading
                  blurb
                  backgroundImage {
                    source_url
                  }
                }
              }
            }
          }
          site {
            siteMetadata {
              paginate
            }
          }
          caseStudies: allWordpressWpCaseStudies(
            sort: {fields: menu_order, order: ASC}
          ) {
            edges {
              node {
                slug
                title
                location
                sector
                acf {
                  isFeatured
                  summary
                }
                featuredImage: featured_image_url {
                  source_url
                }
              }
            }
            pageInfo {
              pageCount
              perPage
              itemCount
              currentPage
            }
          }
          location: allWordpressWpLocation(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                slug
                wordpress_id
                wordpress_parent
              }
            }
          }
          sector: allWordpressWpSector(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                slug
                wordpress_id
                wordpress_parent
              }
            }
          }
        }
      `}
      render={data => <ThisCaseStudiesFilter {...props} data={data} />}
    />
  )
}
