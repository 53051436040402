import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import { CaseStudiesFilter } from '../components/CaseStudies/CaseStudiesFilter'

const CaseStudies = (props) => {
  const {
    data,
    location
  } = props;

  const {
    page,
  } = data;

  const {
    pageTitle,
    yoast
  } = page;

  //const fromFilter = location.state && location.state.fromFilter; // This should not be working

  return (
    <Layout location={location} pageTitle={pageTitle}>
      <SEO
        title={pageTitle}
        desc={yoast.metaDescription}
      />
      <CaseStudiesFilter />
    </Layout>
  );
}

export default CaseStudies

export const pageQuery = graphql`
  query CaseStudiesFilterPageQuery($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      pageId: wordpress_id
      pageTitle: title
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
  }
`
