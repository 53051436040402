import React from 'react'
//import { Link } from 'gatsby'
import { compileQuery } from '../../utils/helpers'

export const CaseStudiesPagination = ({ pageContext, pathPrefix }) => {
  if (!pageContext) {
    return null
  }
  const {
    paged = 1,
    //previousPagePath = null,
    //nextPagePath = null,
    numberOfPages = null,
  } = pageContext
  var pages = []
  for (var i = 0; i < numberOfPages; i++) {
    pages.push(i + 1)
  }

  //const humanPageNumber = previousPagePath + 1 || nextPagePath - 1 || null

  return (
    <nav className="pagination" role="navigation">
      <div className="navbar navbar-menu">
        {pages.map((key, index) => {
          const thisLinkUrl =
            key !== 1
              ? `${pathPrefix}${compileQuery('page', key)}#filter`
              : `${pathPrefix}${compileQuery(1)}#filter`
          return (
            <a
              key={index}
              className={parseInt(key) === parseInt(paged) ? `active` : ''}
              onClick={e => {
                e.preventDefault()
                window.location.href = thisLinkUrl
              }}
              href={thisLinkUrl} // For some reason this is not working in production
            >
              {key}
            </a>
          )
        })}
      </div>
    </nav>
  )
}
